import { useState } from "react";
import { Modal } from "flowbite-react";
import { Swiper, SwiperSlide } from "swiper/react";

import MenuPageLayout from "@/components/layouts/MenuPageLayout";
import { useCollection } from "@/hooks/resources";
import VocabularyType from "@/types/vocabulary-type";

import "swiper/css";
import Skeleton from "react-loading-skeleton";

const VocabularyPage = () => {
  const [vocabularies, { isLoading: isLoadingVocabularies }] =
    useCollection<VocabularyType>("/vocabularies");

  const [currentVocabulary, setCurrentVocabulary] = useState<VocabularyType>();

  return (
    <MenuPageLayout title="Vocabulary">
      <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-4">
        {isLoadingVocabularies
          ? [...Array(12)].map((_, index) => (
              <div
                key={index}
                className="w-full rounded-2xl border p-4 flex flex-col gap-4 items-center"
              >
                <Skeleton
                  containerClassName="w-full leading-none"
                  className="size-64"
                />

                <Skeleton
                  containerClassName="w-1/2 leading-none"
                  className="h-6"
                />
              </div>
            ))
          : (vocabularies || []).map((vocabulary: VocabularyType) => (
              <button
                key={vocabulary.id}
                className="group p-4 border border-solid border-gray-400 rounded-2xl flex flex-col items-center gap-4"
                onClick={() => setCurrentVocabulary(vocabulary)}
              >
                <img
                  src={vocabulary.images[0]?.url}
                  className="object-contain object-center w-full aspect-square rounded-lg"
                />

                <h3 className="text-xl group-hover:underline">
                  {vocabulary.name}
                </h3>
              </button>
            ))}
      </div>

      <Modal
        show={!!currentVocabulary}
        position="center"
        onClose={() => setCurrentVocabulary(undefined)}
        popup
        dismissible
      >
        <Modal.Header />
        <Modal.Body>
          <div className="py-2 flex flex-col items-center gap-4 text-black">
            <Swiper
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              className="w-full rounded-xl ring-4 ring-gray-400"
            >
              {currentVocabulary?.images.map((image) => (
                <SwiperSlide key={image.url}>
                  <img
                    src={image.url}
                    className="block object-cover object-center w-full aspect-square cursor-grab"
                  />
                </SwiperSlide>
              ))}
            </Swiper>

            <h2 className="text-2xl text-black">{currentVocabulary?.name}</h2>
          </div>
        </Modal.Body>
      </Modal>
    </MenuPageLayout>
  );
};

export default VocabularyPage;
